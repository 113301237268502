import { SELL_TYPE_IDS } from '@common/constants';
const {
  useSelector
} = ReactRedux;
const OnlyLottombudContainer = ({
  children
}) => {
  const isLottombud = useSelector(({
    User
  }) => SELL_TYPE_IDS.LOTT === User?.retailer?.sellTypeId);
  if (isLottombud) {
    return children;
  }
  return null;
};
export default OnlyLottombudContainer;