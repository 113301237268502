export const ScrollToTop = () => {
  const history = ReactRouterDOM.useHistory();
  React.useEffect(() => {
    if (!history) {
      return;
    }
    const unlisten = history.listen(() => {
      if (document.documentElement.scrollTop < window.innerHeight / 3) {
        return;
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });
    return () => unlisten();
  }, [history]);
  return null;
};