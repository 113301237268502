import { selectIsSpelombud } from '../selectors';
const {
  useSelector
} = ReactRedux;
const OnlySpelombudContainer = ({
  children
}) => {
  const isSpelombud = useSelector(selectIsSpelombud);
  if (isSpelombud) {
    return children;
  }
  return null;
};
export default OnlySpelombudContainer;