import { formatUserRole } from '@common/utils';
import OnlySpelombudContainer from '@common/components/only-spelombud-container';
import OnlyLottombudContainer from '@common/components/only-lottombud-container';
import './user-menu.less';
const {
  ReactDrawer: Drawer,
  ReactIcon: Icon,
  ReactButton: Button,
  ReactIconButton: IconButton
} = svs.ui;
const {
  ErrorBoundary
} = svs.components.errorBoundary;
const UserMenu = ({
  isOpen,
  onClose,
  username,
  userRole
}) => {
  const urlMinButik = svs.core.urlMapping.get('myBusinessHome');
  const items = {
    'Inloggad som:': username,
    Behörighet: formatUserRole(userRole)
  };
  const handleClick = () => {
    const url = window.location.href;
    window.location = url.replace(location.pathname, svs.core.urlMapping.get('logout'));
  };
  return <Drawer className="site-user-menu nav-menu-nav" isOpen={isOpen} onDimmerClicked={onClose} position="right">
      <ErrorBoundary>
        <div className="site-user-menu-header bg-white u-flex u-flex-column u-flex-align-items-center u-flex-justify-content-center">
          <div className="bg-white padding-right-1 padding-top-1 u-position-absolute u-top-0 u-right-0">
            <IconButton onClick={onClose}>
              <Icon icon="close" />
            </IconButton>
          </div>
          <Icon icon="user" size={600} />
        </div>

        <div className="padding-left-4 margin-left-2">
          <ul className="padding-top-4 margin-top-1 f-content">
            {Object.keys(items).map(label => <li className="margin-bottom-1" key={label}>
                <div className="padding-bottom f-bold">
                  {label}
                </div>
                <div className="padding-bottom">
                  {items[label]}
                </div>
              </li>)}
          </ul>

          <div className="padding-top-3 padding-bottom-1 f-content f-bold">Min profil</div>

          <ul className="f-content">
            <li className="padding-bottom-1 margin-bottom">
              <a className="fc-navy" href={`${urlMinButik}/min-profil`}>
                <Icon icon="user" />
            &nbsp; Min profil
              </a>
            </li>
            <li className="padding-bottom">
              <a className="fc-navy" href={`${urlMinButik}/mitt-ombud`}>
                <Icon icon="new-games" />
            &nbsp; Mitt ombud
              </a>
            </li>
          </ul>

          <div className="padding-top-4 f-content f-bold padding-bottom-1">Externa länkar</div>

          <ul className="f-content">
            <OnlySpelombudContainer>
              <li className="padding-bottom-1 margin-bottom">
                <a className="fc-navy" href="https://spela.svenskaspel.se/ombudsportalen/logga-in?returnUrl=%2Fspela-tillsammans%2Fombud" rel="noreferrer" target="_blank">
                  <Icon icon="nav-spela-tillsammans" />
            &nbsp; Spela tillsammans
                </a>
              </li>
            </OnlySpelombudContainer>
            <li className="padding-bottom-1 margin-bottom">
              <a className="fc-navy" href="https://svenskaspel.resolutmrm.com/login.aspx?case=logedout" rel="noreferrer" target="_blank">
                <Icon icon="business-travel" />
            &nbsp; Materialshoppen
              </a>
            </li>
            <OnlyLottombudContainer>
              <li className="padding-bottom-1 margin-bottom">
                <a className="fc-navy" href="https://www.svenskaspel.se/lottapplikation/logga-in?returnUrl=%2Flottapplikation" rel="noreferrer" target="_blank">
                  <Icon icon="nav-my-games" />
            &nbsp; Lottapplikation
                </a>
              </li>
            </OnlyLottombudContainer>
            <li className="padding-bottom-1 margin-bottom">
              <a className="fc-navy" href="https://svenskaspelutbildning.netcompetence.se/" rel="noreferrer" target="_blank">
                <Icon icon="new-games" />
            &nbsp; Utbildningsportalen
              </a>
            </li>

            <OnlySpelombudContainer>
              <li className="padding-bottom-1 margin-bottom">
                <a className="fc-navy" href="https://spela.svenskaspel.se/#resultat" rel="noreferrer" target="_blank">
                  <Icon icon="nav-results" />
                  &nbsp; Resultat - Sport & Casino
                </a>
              </li>

              <li className="padding-bottom-1 margin-bottom">
                <a className="fc-navy" href="https://www.svenskaspel.se/tur#resultat" rel="noreferrer" target="_blank">
                  <Icon icon="nav-results" />
                  &nbsp; Resultat - Tur
                </a>
              </li>
            </OnlySpelombudContainer>
          </ul>
        </div>

        <div className="margin-top-1 padding-top-4 margin-h-4">
          <Button block onClick={handleClick} transparent>
            Logga ut
          </Button>
        </div>
      </ErrorBoundary>
    </Drawer>;
};
export default UserMenu;