import UserMenu from './user-menu';
import './styles.less';
const {
  useState
} = React;
const {
  ReactIcon: Icon,
  ReactIconButton: IconButton
} = svs.ui;
const {
  SiteHeaderBrand,
  SiteWrapper
} = svs.retailUi;
const {
  isAnonymous
} = svs.core.session.data.userSession;
const SiteHeader = ({
  hasMenu,
  onToggleMenu,
  username,
  userRole
}) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  return <header className="site-header">
      <div className="header header-default" id="top-content" role="banner">
        <div className="header-content">
          <SiteHeaderBrand />
          {hasMenu && <React.Fragment>
              <UserMenu isOpen={isUserMenuOpen} onClose={() => setIsUserMenuOpen(false)} username={username} userRole={userRole} />
              <button aria-controls="navigation-menu-main" aria-expanded="false" aria-label="Meny" className="nav-hamburger icon-button fc-black hamburger-icon" onClick={onToggleMenu} type="button">
                <Icon icon="menu" />
              </button>

            </React.Fragment>}
          {isDrawerOpen && <div className="site-menu visible-xs">
              <div className="close-icon">
                <IconButton onClick={() => setDrawerOpen(false)}>
                  <Icon icon="close" />
                </IconButton>

              </div>
              <div className="menu">
                <SiteWrapper />
              </div>
            </div>}

          {!isAnonymous && <button aria-controls="navigation-menu-main" aria-expanded="false" aria-label="Meny" className="nav-user icon-button fc-black" onClick={() => setIsUserMenuOpen(true)} type="button">
              <Icon icon="user" />
              <span className="nav-hamburger-title">
                {username}
              </span>
            </button>}
        </div>
      </div>
    </header>;
};
export default SiteHeader;