import SiteHeader from './header';
const {
  useSelector
} = ReactRedux;
export const HeaderContainer = ({
  hasMenu,
  onToggleMenu
}) => {
  const userRole = useSelector(({
    User
  }) => User?.role);
  const username = useSelector(({
    User
  }) => User?.name);
  return <SiteHeader hasMenu={hasMenu} onToggleMenu={onToggleMenu} username={username} userRole={userRole} />;
};